import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import isAbsoluteURL from 'is-absolute-url';
import React from 'react';
import { useLocation } from '@reach/router';

import { Button as MuiButton, ButtonOwnProps, Chip, Stack } from '@mui/material';
import { CrossDomainLink } from '@seeka-labs/converge-react';
import { getPartnerLink } from '../shared/hooks/usePartnerCodeLink';

export interface HomepageLink {
  id: string
  href: string
  url: string
  text: string
}

export interface ContentfulAsset {
  id?: string
  url?: string
  publicURL?: string
  alt: string
  //url: String @imageUrl
  file: any
  gatsbyImageData: IGatsbyImageData
  title?: string
}

interface ContentfulAssetImageProps
  extends Omit<Omit<GatsbyImageProps, "alt">, "image"> {
  asset: ContentfulAsset
  url?: string
  alt?: string
  aboveFold?: boolean
}

/// Supports SVGs, childImageSharp is null for SVGs
export function ContentfulAssetImage({
  asset,
  loading,
  url,
  alt,
  style,
  imgStyle,
  aboveFold,
  ...other
}: ContentfulAssetImageProps) {
  // console.log("heree ContentfulAssetImage", props)

  // if (aboveFold && asset && asset.gatsbyImageData && asset?.gatsbyImageData?.images?.fallback?.src) return (
  //   // <>{JSON.stringify(asset, null, 2)}</>
  //   <StaticImage
  //     // {...{
  //     //   ...other,
  //     //   asset: undefined,
  //     //   gatsbyImageData: undefined,
  //     //   image: undefined,
  //     //   publicURL: undefined,
  //     //   url: undefined,
  //     // }}
  //     loading={loading || "lazy"}
  //     // image={asset.gatsbyImageData}
  //     src={asset.gatsbyImageData.images.fallback.src}
  //     alt={alt || asset.alt || asset.title} />
  // )

  return !aboveFold && asset && asset.gatsbyImageData ? (
    <GatsbyImage
      // {...{
      //   ...other,
      //   asset: undefined,
      //   gatsbyImageData: undefined,
      //   image: undefined,
      //   publicURL: undefined,
      //   url: undefined,
      // }}
      loading={loading || "lazy"}
      image={asset.gatsbyImageData}
      alt={alt || asset.alt || asset.title}
    />
  ) : url || (asset && asset.url) || (asset && asset.file && asset.file.url) ? (
    <img
      style={style || imgStyle}
      width={imgStyle && imgStyle.width ? imgStyle.width : undefined}
      height={imgStyle && imgStyle.height ? imgStyle.height : undefined}
      src={url || (asset && (asset.url || asset.file.url))}
      alt={alt || (asset && (asset.alt || asset.title))}
    />
  ) : (
    <></>
  )
}

type KickerProps = {
  icon?: ContentfulAsset
  label: string
  background?: string
}

export function Kicker({ icon, background, ...props }: KickerProps) {
  // console.log('heree', icon.url)

  return (
    <>
      <Chip
        icon={
          icon ? (
            <ContentfulAssetImage
              asset={icon}
              alt={props.label}
              {...icon}
              imgStyle={{ height: "20px", width: "20px" }}
            />
          ) : undefined
        }
        label={props.label}
        sx={{
          padding: "0.5rem 1rem",
          background: background || "#F7F1FF",
          fontWeight: 500,
          color: "#722ED1",
          fontSize: "1rem",
          lineHeight: "1.5rem",
        }}
        variant="filled"
        color="primary"
      />
    </>
  )
}

interface LinkProps {
  to?: string
  href?: string
}

export function Link(
  { to, href, ...props }: LinkProps,
  ref: React.ForwardedRef<unknown>,
) {
  const location = useLocation();
  const url = getPartnerLink(href || to || "", location)
  if (url && isAbsoluteURL(url)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <CrossDomainLink><a href={url} target="_blank" {...props} /></CrossDomainLink>
    )
  }
  return Boolean(url) ? <GatsbyLink to={url} {...props} /> : <MuiButton {...props} />
}

export function NavLink({ ...props }) {
  const link = React.forwardRef<unknown, LinkProps>((props, ref) =>
    Link(props, ref),
  )

  const location = useLocation();
  const to = getPartnerLink(props.to, location)
  const href = getPartnerLink(props.href, location)

  return <CrossDomainLink><MuiButton variant="text" component={link} {...props} to={to} href={href} /></CrossDomainLink>
}

export function NavButtonLink({ ...props }) {
  const location = useLocation();
  const to = getPartnerLink(props.to, location)
  const href = getPartnerLink(props.href, location)

  return <CrossDomainLink><MuiButton variant="text" {...{ ...props, to: to, href: href }} /></CrossDomainLink>
}

type ButtonProps = ButtonOwnProps & {
  href?: string
  to?: string
}

export function Button({
  color = "primary",
  variant = "contained",
  ...props
}: ButtonProps) {
  const location = useLocation();
  const url = getPartnerLink(props.href || props.to, location)
  return (
    <CrossDomainLink>
      <MuiButton
        variant={variant}
        color={color}
        href={url}
        target={isAbsoluteURL(url) ? "_blank" : undefined}
      >
        {props.children}
      </MuiButton>
    </CrossDomainLink>
  )
}

interface ButtonListProps {
  links: HomepageLink[]
  reversed?: boolean
  buttonVariant?: "text" | "outlined" | "contained"
}

export function ButtonList({
  links = [],
  reversed = false,
  ...props
}: ButtonListProps) {
  if (!links || links.length === 0) return null

  return (
    <Stack>
      {links &&
        links.map((link, i) => {
          return (
            <div key={link.id}>
              <Button href={link.href || link.url} variant={props.buttonVariant}>
                {link.text}
              </Button>
            </div>
          )
        })}
    </Stack>
  )
}
